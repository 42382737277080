











































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab6Question5',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        explanation: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      rules: [(v: string) => v?.length <= 1500 || 'Max 1500 characters'],
      questions: [
        {
          en: 'If you were to take a TLC of your Grignard solution before you add it to the dry ice, what would your TLC look like?',
          fr: 'Si vous effectuez une CCM de votre solution de Grignard avant l’ajout de la neige carbonique, comment paraîtra-t-elle?',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
